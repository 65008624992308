import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { SUB_EDITION_USER_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/SubEditionUserRole/Full';
import {
  COMPANY_USER_ROLE_FOR_COMMUNITY_USER_FRAGMENT,
} from '@/graphql/_Fragments/CompanyUserRole/ForCommunityUser';
import { SPEAKER_AUTH_USER_FRAGMENT } from '@/graphql/_Fragments/Speaker/AuthUser';

export const COMMUNITY_USER_CREATE_FRAGMENT = `
  fragment communityUserCreateFragment on CommunityUser {
    uid
    banned
    schemaCode
    firstName
    lastName
    prefix
    suffix
    email
    jobTitle
    employerName
    videoPresentationS3Url
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    companyRoles(filter: {state: "ACCEPTED"}) {
      ...companyUserRoleForCommunityUserFragment
    }
    subEditionRoles {
      ...subEditionUserRoleFullFragment
    }
    speakers(first: 1) {
      ...speakerAuthUserFragment
    }
    blockedUsers {
      uid
      name
    }
    blockedByUsers {
      uid
      name
    }
    profilePrivacyMode
    _authToken
    _needsActivation
    _needsEmailDisambiguated
    _needsPasswordCreated
    _needsNameCreated
  }
  ${SUB_EDITION_USER_ROLE_FULL_FRAGMENT}
  ${COMPANY_USER_ROLE_FOR_COMMUNITY_USER_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${SPEAKER_AUTH_USER_FRAGMENT}
`;
